import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyADWSqtNm4K8tvks8zmrtJ__Zasxm0yJr0",
  authDomain: "git-access.firebaseapp.com",
  databaseURL: "https://git-access.firebaseio.com",
  projectId: "git-access",
  storageBucket: "git-access.appspot.com",
  messagingSenderId: "966404893713",
  appId: "1:966404893713:web:d44b0cdcf15fa0b431d5b0",
  measurementId: "G-GZNJJ814F7"
};


const app = initializeApp(firebaseConfig);
 const fireStore = getFirestore(app);


export {
  fireStore,
};