
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      fontSize: 16,
    },
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover,&:focus': {
        color: theme.palette.primary.dark,
        textDecoration: 'none',
      },
      '&.disable-link': {
        pointerEvents: 'none',
        cursor: 'default',
        [theme.breakpoints.down('xs')]: {
          pointerEvents: 'inherit',
          cursor: 'pointer',
        },
      },
    },
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    h1: {
      fontSize: 24,
      lineHeight: 1.1,
      marginBottom: 15,
      color: theme.palette.text.primary,
    },
    h2: {
      fontSize: 20,
      lineHeight: 1.1,
      marginBottom: 15,
      color: theme.palette.text.primary,
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.1,
      marginBottom: 15,
      color: theme.palette.text.primary,
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.1,
      marginBottom: 15,
      color: theme.palette.text.primary,
    },
    h5: {
      fontSize: 18,
      marginBottom: 15,
      lineHeight: 1.1,
      color: theme.palette.text.primary,
    },
    h6: {
      fontSize: 16,
      marginBottom: 15,
      lineHeight: 1.1,
      color: theme.palette.text.primary,
    },
    '#root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    ul: {
      paddingLeft: 40,
    }
  },
  headerRoot: {
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.08)',
    paddingTop: 20,
    paddingBottom: 20,
  },
  headerInnerRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  logoRoot: {
    height: 40,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  headerContent: {
    flex: 1,
    marginLeft: 20,
    marginRight: 20,
    textAlign: 'center',
  },
  mainContentRoot: {
    flex: 1,
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  footerRoot: {
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.08)',
    paddingTop: 20,
    paddingBottom: 20,
  },
  pageHeader: {
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    paddingBottom: 20,
    marginBottom: 40,
    textAlign: 'center',
  },
  gutterBottomRoot: {
    marginBottom: 16,
  },
  formControl: {
    minWidth: 120,
    width:"100%",
    marginBottom: 40,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
