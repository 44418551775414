import AppMessageView from '../MessageView';
import {useInfoViewActionsContext, useInfoViewContext} from "./InfoViewContextProvider";
import AppLoader from "../Loader";

const AppInfoViewContext = () => {
  const { error, loading, message } = useInfoViewContext();
  const { clearInfoView } = useInfoViewActionsContext();

  const showMessage = () => {
    return (
      <AppMessageView
        variant='success'
        message={message.toString()}
        clearInfoView={clearInfoView}
      />
    );
  };

  const showError = () => {
    return (
      <AppMessageView
        variant='warning'
        message={error.toString()}
        clearInfoView={clearInfoView}
      />
    );
  };

  return (
    <>
      {loading && <AppLoader />}

      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default AppInfoViewContext;
