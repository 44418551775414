import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {Box, Button, Grid, TextField} from '@material-ui/core';
import useStyles from "./App.styles";
import Copyright from "./Footer/Copyright";
import {fireStore} from "./utils/firebaseConfig";
import {checkUserNotExist, isValidEmail, isValidPurchase, sendInvitation} from "./utils/Utils";
import InfoView from "./InfoView";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import {useInfoViewActionsContext} from "./InfoView/InfoViewContextProvider";

const App = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const infoViewActionsContext = useInfoViewActionsContext();
  const [username, setGitUsername] = useState('');
  const [purchase_code, setPurchaseCode] = useState('');
  const [emailError, setEmailError] = useState('');
  const [gitUsernameError, setGitUsernameError] = useState('');
  const [purchaseCodeError, setPurchaseCodeError] = useState('');
  const [repoName, setRepoName] = React.useState("crema-mui-4.x");

  const handleChange = (event) => {
    setRepoName(event.target.value);
  };


  const onSubmit = () => {
    if (email === '') {
      setEmailError("Email is required")
    } else if (!isValidEmail(email)) {
      setEmailError("Email is invalid")
    } else if (username === '') {
      setGitUsernameError("GitHub username is required")
    } else if (purchase_code === '') {
      setPurchaseCodeError("Purchase Code is required")
    } else {
      isValidPurchase(purchase_code, infoViewActionsContext).then(async (data) => {
        console.log("isValidPurchase", data)
        const alreadyRequested = await checkUserNotExist(fireStore, purchase_code, repoName);
        console.log("is alreadyRequested: ", alreadyRequested);
        if (alreadyRequested) {
          infoViewActionsContext.fetchError("Invitation already sent to this user")
        } else {
          sendInvitation(fireStore, repoName, {
            repo: repoName,
            email,
            username,
            purchase_code: purchase_code
          }, infoViewActionsContext);
        }
      });
    }

  };

  return (
    <>
      <header className={classes.headerRoot}>
        <Container maxWidth="lg">
          <Box className={classes.headerInnerRoot}>
            <Box className={classes.logoRoot}>
              <img src="/images/logo-with-name.png" alt="Logo" style={{height: 32}}/>
            </Box>
            <Box className={classes.headerContent}>
              <Box mb={0} component="h1" fontFamily="Gilroy-Bold" fontSize={{xs: 18, md: 24}}>
                Crema- GitHub Access
              </Box>
            </Box>
            <Box width={{md: 160}}/>
          </Box>
        </Container>
      </header>
      <Box className={classes.mainContentRoot}>
        <Container maxWidth="lg">

          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <form className={classes.root} noValidate autoComplete="off">
                <Box style={{marginBottom:12}}>
                  <TextField
                    id="outlined-basic1"
                    label="Email Address"
                    fullWidth variant="outlined"
                    value={email}
                    error={Boolean(emailError)}
                    helperText={emailError}
                    onChange={(e) => {
                      setEmailError('');
                      setEmail(e.target.value)

                    }}/>
                </Box>
                <Box style={{marginBottom:12}}>
                  <TextField
                    id="outlined-basic1"
                    label='Github Username'
                    fullWidth variant="outlined"
                    error={Boolean(gitUsernameError)}
                    helperText={gitUsernameError}
                    value={username} onChange={(e) => {
                    setGitUsernameError('');
                    setGitUsername(e.target.value)
                  }}/>
                </Box>
                <Box style={{marginBottom:12}}>
                  <TextField
                    id="outlined-basic"
                    label="Template Purchase Code"
                    fullWidth variant="outlined"
                    error={Boolean(purchaseCodeError)}
                    helperText={purchaseCodeError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Purchase Code" placement="top">
                            <a
                              target="_blank" rel="noopener noreferrer"
                              href='https://help.market.envato.com/hc/en-us/articles/202822600-Where-Is-My-Purchase-Code-'>
                              <InfoOutlinedIcon/>
                            </a>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    value={purchase_code} onChange={(e) => {
                    setPurchaseCodeError('');
                    setPurchaseCode(e.target.value)
                  }}
                  />
                </Box>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-version-native-simple">Select Crema Version</InputLabel>
                  <Select
                    native
                    value={repoName}
                    label="Select Crema Version"
                    onChange={handleChange}
                    inputProps={{
                      name: 'Select Crema Version',
                      id: 'outlined-version-native-simple',
                    }}
                  >
                    <option value='crema-mui-4.x'>Crema MUI v-4.x</option>
                    <option value='crema-mui-ts-4.x'>Crema MUI TS v-4.x</option>
                    <option value='crema-mui-nextjs-4.x'>Crema MUI Next v-4.x</option>
                    <option value='crema-mui-nextjs-ts-4.x'>Crema MUI Next TS v-4.x</option>
                    <option value='crema-antd-4.x'>Crema Antd v-4.x</option>
                    <option value='crema-antd-ts-4.x'>Crema Antd TS v-4.x</option>
                    <option value='crema-antd-nextjs-4.x'>Crema Antd Next v-4.x</option>
                    <option value='crema-antd-next-ts-4.x'>Crema Antd Next TS v-4.x</option>
                    <option value='crema-3.x'>Crema MUI v-3.x</option>
                    <option value='crema-ant-3.x'>Crema Ant v-3.x</option>
                    <option value='crema_template-2.0'>Crema V-2.0</option>
                  </Select>
                </FormControl>
                <Box style={{marginBottom:12}}>
                  <Button size="large" variant="contained" color="primary" onClick={onSubmit}>
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography className={classes.gutterBottomRoot}>We believe the right way to update the product is using
                the git repo. This way you can have a detailed understanding on what have changed and can participate
                more.</Typography>
              <Typography className={classes.gutterBottomRoot}>We manage all our repository at Github and to access our
                git repo, you must have a GitHub account.</Typography>
              <Typography className={classes.gutterBottomRoot}>Please fill the form given in right side to request the
                access to the purchased product. You will be added as a collaborator and will be given following
                privileges:</Typography>
              <ul className={classes.listRoot}>
                <li>Latest dev version repo access</li>
                <li>Instant access to bug fixes and features before we release</li>
                <li>You can make pull requests and we will merge them if they makes Crema React more powerful &
                  sensible. So that others can also get benefit of that.
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.footerRoot}>
        <Container maxWidth="lg">
          <Copyright/>
        </Container>
      </Box>
      <InfoView/>
    </>
  );
};

export default App
