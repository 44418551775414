
import {createTheme} from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0A8FDC',
    },
    secondary: {
      main: '#F04F47',
    },
    error: {
      main: '#F04F47',
    },
    background: {
      default: '#fff',
    },
  }, typography: {
    fontFamily: ["Gilroy-Regular", "Gilroy-Bold", "Gilroy-Light", "Gilroy-Medium", 'sans-serif'].join(','),
  },
});

export default theme;
