import axios from "axios";
import {addDoc, collection, getDocs} from "firebase/firestore";

export const gitHubClient = axios.create({
  baseURL: process.env.REACT_APP_GitHubBaseUrl,
  headers: {
    'Authorization': 'Bearer ' + process.env.REACT_APP_GitHubToken
  },
});
export const themForestClient = axios.create({
  baseURL: process.env.REACT_APP_ThemeForestBaseUrl,
  headers: {
    'Authorization': 'Bearer ' + process.env.REACT_APP_ThemeForestToken
  },
});
export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

export const isValidPurchase = (code, infoViewActionsContext) => {
  return new Promise((resolve, reject) => {
    infoViewActionsContext.fetchStart();
    themForestClient
      .get(`sale?code=${code}`)
      .then((data) => {
        if (data.status === 200) {
          infoViewActionsContext.fetchSuccess();
          return resolve(data.data);
        } else {
          infoViewActionsContext.fetchError('No purchase belonging to the current user found with that code');
          return reject('No purchase belonging to the current user found with that code');
        }
      })
      .catch(function (error) {
        // console.log('isValidPurchase Error: ', error);
        infoViewActionsContext.fetchError('No purchase belonging to the current user found with that code');
        return reject('');
      });
    return Promise.resolve();
  });
};

export const checkUserNotExist = (fireStore, purchase_code, repoName) => {
 return   getDocs(collection(fireStore, "crema_github_users"))
    .then((querySnapshot)=>{
      const newData = querySnapshot.docs
        .filter((doc) => {
          const data = doc.data();
           if(data.purchase_code ===purchase_code && repoName===data.repo){
             return data;
           }else {
             return null;
           }
        });
      console.log("Data", newData);
      return newData.length > 0;
    }).catch((error)=>{
      console.log("Error", error);
    })
};

export const addUserToDB = (fireStore, data, infoViewActionsContext) => {
  addDoc(collection(fireStore, "crema_github_users"), data).then((docRef) => {
    console.log("Document written with ID: ",docRef.id /*"HJKY"+docRef.id+"JUghjtG*("*/);
    infoViewActionsContext.showMessage("Invitation has been sent successfully!")
  }).catch((e => {
    infoViewActionsContext.fetchError("Something went wrong, Please try again later");
  }));
};

export const sendInvitation = (firestore, repo, userInfo, infoViewActionsContext) => {
  infoViewActionsContext.fetchStart();
  gitHubClient
    .put(`${repo}/collaborators/${userInfo.username}`, {payload: {}})
    .then(({data}) => {
      console.log("data", data)
      if (data.error === 404) {
        console.log('isValidPurchase Failed: ', data.description);
        infoViewActionsContext.fetchError(data.description);
      } else {
        addUserToDB(firestore, userInfo, infoViewActionsContext)
      }
    })
    .catch(function (error) {
      console.log('isValidPurchase Error: ', error.message);
      infoViewActionsContext.fetchError('Invalid GitHub username! Please try again with valid github username.');
    });
};
